import React from 'react';
import { useTranslation } from 'react-i18next';

export const Introduce = () => {
    // 添加一个样式组件
    const styles = {
        titleSpacing: {
            marginTop: '2em', // 这里的 '2em' 可以根据需要调整，以创建所需的空间
        }
    };

    const {t} = useTranslation();


    return (
        <div>
            {/* 应用样式到标题 */}
            <h2 style={styles.titleSpacing}><span className="header__group-two">{t('introduction.title')}</span></h2>
            <p>
                <span className="header__group-two">
                    <br />
                    {t('introduction.part1')}
                    <br />
                    <br />
                    {t('introduction.part2')}
                    <br />
                    <br />
                    {t('introduction.part3')}
                </span>
            </p>
        </div>
    )
}

