import React from 'react';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  onClick: () => void
};

/**
 * React component for the Header Section.
 */
export const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <h1>
        <a href="./" title="sudoku"><span className="header__group-one">{t('sudoku')}</span><span className="header__group-two">{t('game')}</span></a>
      </h1>
      <h2 onClick={props.onClick}>
        {t('NewGame')}
      </h2>
    </header>
  )
}
