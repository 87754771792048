import React from 'react';
import { useSudokuContext } from '../context/SudokuContext';
import { useTranslation } from 'react-i18next';

type DifficultyProps = {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
};

/**
 * React component for the Difficulty Selector.
 */
export const Difficulty = (props: DifficultyProps) => {
  let { difficulty } = useSudokuContext();
  const { t } = useTranslation();

  return (
    <div className="status__difficulty">
      <span className="status__difficulty-text">{t('Diffculty')}:&nbsp;&nbsp;</span>
      <select name="status__difficulty-select" className="status__difficulty-select" defaultValue={difficulty} onChange={props.onChange}>
        <option value="Easy">{t('diffculty.Easy')}</option>
        <option value="Medium">{t('diffculty.Medium')}</option>
        <option value="Hard">{t('diffculty.Hard')}</option>
      </select>
    </div>
  )
}
