import React from 'react';
import { Game } from './Game';
import './App.css';
import { SudokuProvider } from './context/SudokuContext';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

/**
 * App is the root React component.
 */
export const App: React.FC<{}> = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyD80N76btq_E4b1jnkXiL2HoOj8OvlKk30",
    authDomain: "sudoku-967d3.firebaseapp.com",
    projectId: "sudoku-967d3",
    storageBucket: "sudoku-967d3.appspot.com",
    messagingSenderId: "70072184469",
    appId: "1:70072184469:web:28f574f55154e33a84f59c",
    measurementId: "G-4WRRG6845T"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  
  return (
    <SudokuProvider>
      <Game />
    </SudokuProvider>
  );
}
